
import { defineComponent, ref } from 'vue';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useStore } from 'vuex';
import * as Yup from 'yup';
import { Actions } from '@/store/enums/StoreEnums';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

export default defineComponent({
  name: 'password-reset',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label('Email'),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute('data-kt-indicator', 'on');

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.FORGOT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              text: 'Password reset email have been successfully sent!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok, got it!',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-primary',
              },
            });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Try again!',
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          });

        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
